

export const DEBUG = false; // NOTE: Can't use .env here because Client-side can't directly access Server side Config (and shouldn't). Could create means for Client to reach out to Server to get this data (e.g. /config Path)
export const VERBOSE = false;


export const QA: string = "qa";
export const PROD: string = "prod";
export const ENVIRONMENT: string = PROD;

export const ENRICHYA: string = "enrichya";
export const AI4YOUTH: string = "ai4youth";
export const APP: string = AI4YOUTH;




// ============================= Time export constants ================================
export const ONE_SECOND_MILLIS = 1000;
export const ONE_MINUTE_MILLIS = 60 * ONE_SECOND_MILLIS;

export const FIVE_MINUTE_MILLIS = 5 * ONE_MINUTE_MILLIS;
export const HALF_HOUR_MILLIS = 30 * ONE_MINUTE_MILLIS;
export const ONE_HOUR_MILLIS = 60 * ONE_MINUTE_MILLIS;

export const TWELVE_HOUR_MILLIS = 12 * ONE_HOUR_MILLIS;
export const ONE_DAY_MILLIS = 24 * ONE_HOUR_MILLIS;
// =============================================================================


export const USE_ASSISTANTS = false;


// Good Voices: 
// -- Female: Yan, Jenny, Aria
// -- Male: Roger, Steffan

// Instructor Info
export const INSTRUCTOR_NAME = "Jenny";
export const INSTRUCTOR_PROFILE_PIC_SRC = require( "../assets/instructor_female_1.png" );
export const IDLE_INSTRUCTOR_VIDEO = "IdleInstructorAnimation.mp4";

// Error Handling
export const PURCHASE_NOT_FOUND_IMAGE = require("../assets/errorOccurred.png");// "purchaseNotFound.png"; // TODO: Not used right now (no reason)
export const ERROR_OCCURRED_IMAGE = "errorOccurred.png";

// Timeouts
export const LECTURE_SLIDE_PAUSE_BEFORE_WRAP_UP = 1000; // 1 Seconds
export const MIN_SILENCE_BEFORE_NEXT_SPEAK = 5000; // 5 Seconds
export const MIN_SILENCE_BEFORE_NEXT_SLIDE = 1000; // 1 Seconds
export const MAX_WAIT_BEFORE_TRY_SPEAK = 1000; // 1 Seconds

export const DELAY_BEFORE_SLIDE_MOVE_ON = 1000; // 1 Seconds

export const DEFAULT_NOTIFICATIONS_ENABLED = true;
export const DEFAULT_NOTIFICATION_LIFESPAN = 8000; // 8 Seconds
export const NOTIFICATION_SPAWN_DELAY = 3000; // 3 Seconds

export const NAVIGATION_BUTTON_DEBOUNCE_DELAY = 3000; // 2 Seconds

export const DEFAULT_Q_AND_A_SLIDE_MOVE_ON_DELAY = 6 * ONE_MINUTE_MILLIS;
export const DEFAULT_ACTIVITY_SLIDE_MOVE_ON_DELAY = 3 * ONE_MINUTE_MILLIS;


// Default Images for Welcome Slide and End of Presentation Slide
export const AI4YOUTH_WELCOME_IMAGE_URL = require( "../assets/AI4YouthWelcomeImage.png" );
export const ENRICHYA_WELCOME_IMAGE_URL = require( "../assets/EnrichyaWelcomeImage.png" );
export const DEFAULT_WELCOME_IMAGE_URL = APP == AI4YOUTH ? AI4YOUTH_WELCOME_IMAGE_URL : ENRICHYA_WELCOME_IMAGE_URL;
//export const PRESENTATION_END_IMAGE_URL = "https://cdn.mos.cms.futurecdn.net/6nYzMLXUjkaVomaBZ8sNRZ.jpg";
// TODO: ^ is not used since Database needs support endSlideImageURL field


// Audio
export const USE_BROWSER_SPEECH_SYNTHESIS_API = false;

export const DEBUG_VOICE_SPEED = 1.0; // Ranges from 0.1 to 10.0
export const VOICE_SPEED = 0.6; // Ranges from 0.1 to 10.0

export const INSTRUCTOR_VOICES = ["Yan", "Jenny", "Zira", "Samantha", "Alice"];
export const FAKE_STUDENT_VOICES = ["Roger", "Stephan", "Mark", "Aaron", "Arthur"];


// Canned Messages
export const WAIT_UNTIL_NO_ONE_SPEAKING_NOTIFICATION = "Please wait until no one is speaking before sending";
export const SLIDE_TIME_LIMIT_MESSAGE = "We'll need to move on for now to have time for more material. You'll have an opportunity to ask questions shortly.";
export const QUESTION_LIMIT_MESSAGE = "The engagement is great. Let's move on for now. You'll have another opportunity to ask questions here soon";
export const QUESTIONS_PAUSED_NOTIFICATION = "Questions are paused until the next Q&A Slide";
export const END_OF_PRESENTATION_MESSAGE = "We've reached the end of the presentation";
//export const SERVER_ERROR_TEXT =  "I'm experiencing network issues. I can continue with the lecture but I may not be able to answer questions or respond to you. Please click Previous and then Next or try again later.";
export const SERVER_ERROR_TEXT = "Sorry, I was not able to answer your question due to a technical issue. Please click Previous button and click Next button to reload the current slide. You can then ask your question again.";


// Question Limits
export const MAX_QUESTIONS_ALLOWED_PER_LECTURE_SLIDE = 3;
export const MAX_QUESTIONS_ALLOWED_PER_Q_AND_A_SLIDE = 10; // TODO: Was 10 per requirements, but 3 for testing


// Slide Type Key Phrases
export const Q_AND_A_SLIDE_KEY_PHRASE = "Q&A Slide";
export const ACTIVITY_SLIDE_KEY_PHRASE = "Activity Slide";
export const QUIZ_SLIDE_KEY_PHRASE = "Quiz Slide";

export const DEFAULT_MAX_SLIDES = 100;

export const AUTO_CHANGE_SLIDES = true;

export const FAKE_STUDENT_NAME = "Mario";
export const FAKE_STUDENT_PROFILE_PIC_SRC = require( "../assets/fake_student_1.png" );
export const FAKE_STUDENT_ASK_QUESTION_DELAY = 100; // Milliseconds
export const IDLE_FAKE_STUDENT_VIDEO = "IdleFakeStudentAnimation.mp4";

export const TEST_STUDENT_NAME = "Oliver";
export const TEST_STUDENT_VOICE = "Steffan";
export const TEST_STUDENT_PROFILE_PIC_SRC = require( "../assets/fake_student_2.png" );



export const DEBUG_SLIDE_NUMBER_START = 0;
export const SLIDE_NUMBER_START = DEBUG ? DEBUG_SLIDE_NUMBER_START : 0;



export const DEFAULT_FAKE_STUDENT_FOLLOW_UP_DELAY = 5000; // 5 Seconds
export const DEFAULT_INSTRUCTOR_FOLLOW_UP_DELAY = 10000; // 10 Seconds
export const DEFAULT_BACKGROUND_PROMPT_DELAY = 15000; // 15 Seconds

export const DEFAULT_SLIDE_MOVE_ON_DELAY = 120000; // 2 Minutes






export const DEBUG_SERVER_ORIGIN = "https://ai4youth.org:3003";

// NOTE: Heroku assigns PORT number automatically and handles routing Requests approrpiately -- Need not include ourselves (at least not on Client)
//export const PROD_AI4YOUTH_SERVER_ORIGIN = "https://ai4youth.org/aiinstructor"; // PROD_TODO: Change this to the Server's Domain
//export const PROD_AI4YOUTH_SERVER_ORIGIN = "https://aiinstructor-dfb5c74cdf96.herokuapp.com"; // PROD_TODO: TEMPORARY FOR TESTING
export const PROD_AI4YOUTH_SERVER_ORIGIN = "https://app.ai4youth.org";
export const QA_AI4YOUTH_SERVER_ORIGIN = "https://qa.ai4youth.org";

export const PROD_ENRICHYA_SERVER_ORIGIN = "https://app.enrichya.org";
export const QA_ENRICHYA_SERVER_ORIGIN = "https://qa.enrichya.org";

//export const PORT = process.env.PORT || 3003; // NOTE: PORT is not needed here for Client. Heroku Dynamically assigns Port for Server so not clear the Port -- Default should be fine?


const getServerOrigin = () => {
    switch( APP ) {
        case AI4YOUTH:
            return DEBUG ? DEBUG_SERVER_ORIGIN : (ENVIRONMENT == PROD ? PROD_AI4YOUTH_SERVER_ORIGIN : QA_AI4YOUTH_SERVER_ORIGIN);
        case ENRICHYA:
            return DEBUG ? DEBUG_SERVER_ORIGIN : (ENVIRONMENT == PROD ? PROD_ENRICHYA_SERVER_ORIGIN : QA_ENRICHYA_SERVER_ORIGIN);
        default:
            return DEBUG_SERVER_ORIGIN;
    }
}
const getServerWebsocketsOrigin = () => {
    switch( APP ) {
        case AI4YOUTH:
            return DEBUG ? "wss://ai4youth.org:3003" : "wss://ai4youth.org";
        case ENRICHYA:
            return DEBUG ? "wss://enrichya.org:3003" : "wss://enrichya.org";
        default:
            return "wss://ai4youth.org:3003";
    }
}

export const SERVER_ORIGIN = getServerOrigin();
export const SERVER_WEBSOCKETS_ORIGIN = getServerWebsocketsOrigin();





export {};
