

enum AppMode {
  PRESENTATION = "presentation",
  TEXT = "text",
}

enum AppState {
  HEALTHY = "healthy",
  READY = "ready", // TODO: Not need? (got Healthy instead ??)
  ERROR = "error",

  LOADING = "loading",
  RETRYING = "retrying",
}

enum MessageType {
  LECTURE = "lecture",
  CHAT = "chat",
}

enum PromptType {
  GENERAL = "generalPrompt",
  QUESTION_ANSWER = "questionAnswer",
}

enum SpeakerType {
  INSTRUCTOR = "instructor",
  STUDENT = "student",
}


export {
  AppMode,
  AppState,

  MessageType,
  PromptType,

  SpeakerType,
}

